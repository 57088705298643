import { useLocation, navigate } from '@gatsbyjs/reach-router'
import axios from 'axios'
import { withPrefix } from 'gatsby'
import i18n from 'i18next'
import React, { useEffect, useState } from 'react'
import {
  I18nextProvider,
  useTranslation,
  initReactI18next,
} from 'react-i18next'

import {
  Dialog,
  DialogContent,
  Grid,
  Message,
  styled,
} from '@deliveryhero/armor'

import { LoadingSpinner } from '../../components/Atoms/LoadingSpinner'
import { LandingPageRenderer } from '../../components/LandingPageRenderer'
import { Layout } from '../../components/layout'
import { toastError } from '../../components/toasts/Error'
import { crossDomainConfig } from '../../config/global'
import { DenormalizedLandingPage } from '../../containers/landing-page/selectors'
import { validateInternationalPhoneNumber } from '../../utils/field-validations'
import { report } from '../../utils/gtm'
import {
  FORM_1_SUBMIT,
  FORM_PAGE,
  PAGE_VIEW,
  FORM_2_SUBMIT,
  FORM_2_SUBMIT_SUCCESSFUL,
} from '../../utils/gtm/constants'

import { requestCreator } from './request-creator'
import { validateAllFields } from './utils'

import 'react-toastify/dist/ReactToastify.min.css'

i18n.use(initReactI18next).init({
  resources: {},
  lng: 'en',
  fallbackLng: 'en',
  debug: true,
  interpolation: {
    escapeValue: false,
    formatSeparator: ',',
  },
})

const CenteredGrid = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
`
const LandingPage: React.FC<{
  pageContext: {
    landingPage: DenormalizedLandingPage
    landingPageContentIndex: number
    resources: Record<string, string>
  }
}> = ({ pageContext: { landingPage, landingPageContentIndex, resources } }) => {
  const lng =
    landingPage.landingPageContents[landingPageContentIndex].language.id

  if (!i18n.hasResourceBundle(lng, 'translation')) {
    i18n.addResourceBundle(
      lng,
      'translation',
      resources[lng] ? resources[lng].translation : resources.en.translation,
    )
    i18n.changeLanguage(lng)
  }

  const [t] = useTranslation()
  const location = useLocation()
  const [isLoading, setIsLoading] = useState(false)
  const [hasError, setHasError] = useState(null)

  useEffect(() => {
    report({
      event: PAGE_VIEW,
      pageUrlPath: location.pathname,
    })
  }, [location.pathname])

  const errorPrettifier = {
    duplicate: t('LandingPage.DuplicateApplicant'),
  }

  // Required to track users across domains
  const getLinkerParam = () => {
    const myWindow: typeof window & {
      ga?: { getAll: () => { get: (param: string) => string }[] }
    } = window

    const linkerParamParts =
      typeof myWindow.ga !== 'undefined'
        ? myWindow.ga.getAll?.()[0].get('linerParam')?.split('=')
        : []

    return linkerParamParts?.length >= 2 ? linkerParamParts : []
  }

  const validatePhone = async (
    data: Record<string, string>,
  ): Promise<{ valid: true; formatted: string } | { valid: false }> => {
    try {
      const response = await axios.post(
        `${crossDomainConfig.araraApiBaseUrl}/v1/public/validate_phone`,
        {
          phone_number: /^[+0]/.test(data.phone_number)
            ? data.phone_number
            : `0${data.phone_number}`,
          brand: landingPage.theme.id,
          country_code: landingPage.country.id,
        },
      )

      return { valid: true, formatted: response.data.phone_number }
    } catch (e) {
      if (e.response.status === 400 || e.response.status === 404) {
        return { valid: false }
      }

      // In other cases (e.g. 5xx errors) we want to still allow
      // international phone numbers:

      if (validateInternationalPhoneNumber(data.phone_number)) {
        return { valid: true, formatted: data.phone_number }
      }

      return { valid: false }
    }
  }

  const generateError = (validations = []) =>
    validations
      .map(validation => t(`LandingPage.${validation}_error`))
      .join(' ')

  const onFormSubmit = async (data: Record<string, string>) => {
    const fieldWithError = validateAllFields(
      landingPage.landingPageContents[landingPageContentIndex].fields,
      data,
    )
    if (fieldWithError) {
      let error = t('LandingPage.AllFieldsAreRequired')
      if (fieldWithError.settings.validations?.length > 0) {
        error += `. ${t('LandingPage.Field')} "${fieldWithError.text}":`
        error += ` ${generateError(fieldWithError.settings?.validations)}`
      }
      toastError(error)
      window.turnstile.reset(window.turnstileWidgetId)
      window.scrollTo(0, 0)
    } else if (data && typeof data.age !== 'undefined' && !data.age) {
      // Check if age is in the form, otherwise don't show an underage error
      toastError(t('LandingPage.YouCannotApplyIfUnderage'))
      window.turnstile.reset(window.turnstileWidgetId)
      window.scrollTo(0, 0)
    } else {
      setIsLoading(true)

      const validateResult = await validatePhone(data)

      if (!validateResult.valid) {
        window.turnstile.reset(window.turnstileWidgetId)
        toastError(t('LandingPage.PhoneNumberNotValid'))
        setIsLoading(false)
        return
      }

      const dataWithFormattedPhoneNumber = {
        ...data,
        phone_number: validateResult.formatted,
      }

      const url = `${crossDomainConfig.araraApiBaseUrl}/v1/applicants`
      const searchParams = new URLSearchParams()

      const linkerParam = getLinkerParam()

      if (linkerParam.length) {
        searchParams.append(linkerParam[0], linkerParam[1])
      }

      report({
        event: FORM_2_SUBMIT,
        formElements: Object.keys(dataWithFormattedPhoneNumber).length,
      })

      axios
        .post(
          `${url}?${searchParams.toString()}`,
          requestCreator(
            landingPage.landingPageContents[landingPageContentIndex],
            dataWithFormattedPhoneNumber,
          ),
        )
        .then(response => {
          setHasError(null)
          report({
            event: FORM_2_SUBMIT_SUCCESSFUL,
            formElements: Object.keys(dataWithFormattedPhoneNumber).length,
          })
          // TODO: listen to GTM submission before navigate
          setTimeout(() => {
            navigate(
              `${crossDomainConfig.araraDashboardUrl}/public/application/${response.data.data.id}`,
            )
          }, 1000)
        })
        .catch(err => {
          console.error(err)
          window.scrollTo(0, 0)
          window.turnstile.reset(window.turnstileWidgetId)

          if (err.response?.data?.errors?.length) {
            if (err.response.data.errors[0].detail === 'duplicate') {
              navigate(
                `${crossDomainConfig.araraDashboardUrl}/public/application/duplicate`,
              )
            } else {
              setHasError(errorPrettifier[err.response.data.errors[0].detail])
            }
          } else {
            setHasError(
              `${t('LandingPage.StatusCode')}: ${err.response.status}`,
            )
          }
        })
        .then(() => setIsLoading(false))
    }
  }

  const onFieldChange = (data: Record<string, string>, name: string) => {
    if (name === 'vehicle')
      report({
        event: FORM_1_SUBMIT,
        pageType: FORM_PAGE,
        Brand: landingPage.theme.brandCode.toUpperCase(),
        cityEntered: data.city,
        vehicleEntered: data.vehicle,
      })
  }

  const switchLanguage = (newLandingPageContentIndex: number) => {
    // When it's the main language the path should be only /
    const newLanguage =
      newLandingPageContentIndex === 0
        ? '/'
        : `/${landingPage.landingPageContents[newLandingPageContentIndex].language.id}`

    const oldLanguage =
      landingPageContentIndex === 0
        ? '/'
        : `/${landingPage.landingPageContents[landingPageContentIndex].language.id}`

    const newPath = [
      window.location.origin,
      window.location.pathname.replace(oldLanguage, newLanguage),
      // keep slash because s3 redirects again if not present, removing query params, dont keep slash in first lang
      newLandingPageContentIndex !== 0 ? '/' : '',
      window.location.search,
    ].join('')

    console.log('Redirecting to: ', newPath)

    window.location.href = newPath
  }
  return (
    <I18nextProvider i18n={i18n}>
      <Layout
        mainClass="landing-page-root"
        headless
        seo={landingPage.landingPageContents[landingPageContentIndex].seo}
        iconUrl={withPrefix(landingPage.theme.logoIcon)}
      >
        {isLoading && (
          <Dialog open={isLoading} disableCloseButton minWidth="150px">
            <DialogContent>
              <CenteredGrid>
                <LoadingSpinner width="50px" />
              </CenteredGrid>
            </DialogContent>
          </Dialog>
        )}

        {hasError !== null && (
          <Message error id="error-container" onClose={() => setHasError(null)}>
            {t('LandingPage.ThereWasAnError')}: {hasError}
          </Message>
        )}

        <LandingPageRenderer
          previewMode={false}
          landingPage={landingPage}
          selectedLandingPageContentIdx={landingPageContentIndex}
          onFormSubmit={onFormSubmit}
          onFieldChange={onFieldChange}
          onChangeLandingPageContextIdx={switchLanguage}
        />
      </Layout>
    </I18nextProvider>
  )
}

export default LandingPage
